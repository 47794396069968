/* istanbul ignore file */
import React from 'react';
import { Root, Routes, addPrefetchExcludes, useSiteData } from 'react-static';
import { Router } from '@reach/router';
import styled, { ThemeProvider } from 'styled-components';

import GlobalStyles from '@nmx/template/dist/componentLibrary/foundations/GlobalStyles';
import Loading from '@nmx/template/dist/componentLibrary/components/Loading';
import Theme from '@nmx/template/dist/componentLibrary/Theme';

export const LoadingStyled = styled(Loading)`
  margin-top: 25%;
`;

// These are the pages we want to prevent react-static from prefetching the route information on.
// Once these pages are migrated to react static, they can be removed from the list.
// They are being prefetched because they are linked to on the page.
addPrefetchExcludes([
  'disability-insurance/disability-insurance-calculator',
  'life-and-money',
  'life-insurance/life-insurance-calculator',
  'retirement-calculator',
  'financial/advisor/',
  '/email-landing/prospect/get-the-most-from-your-first-advisor-meeting',
]);

function App() {
  const getConfig = () => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { config } = useSiteData(); // NOSONAR
      return config;
    } catch (err) {
      // local hotloader won't work with useSiteData so we mock it for local dev.
      return {
        nmxTemplateVersion: '3.5.2',
        basePath: 'https://qa.new.northwesternmutual.com',
      };
    }
  };
  return (
    <Root>
      <ThemeProvider theme={Theme.render(typeof window !== 'undefined' ? window.innerWidth : 480, typeof window !== 'undefined' ? window.innerHeight : 900)}>
        <React.Suspense
          fallback={
            <LoadingStyled>Loading content</LoadingStyled>
          }>
          <GlobalStyles config={getConfig()} />
          <Router>
            <Routes default />
          </Router>
        </React.Suspense>
      </ThemeProvider>
    </Root>
  );
}

export default App;
